import React, { useEffect } from 'react';
import { string } from 'prop-types';

import setItem from '@invitato/helpers/dist/storage/set';
import getItem from '@invitato/helpers/dist/storage/get';
import isDarkmode from '@invitato/helpers/dist/isDarkmode';
import getDeviceOS from '@invitato/helpers/dist/getDeviceOS';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Image,
  Center,
  // hooks
  useDisclosure,
} from '@chakra-ui/react';

import * as txt from './locales';

const KEY_LOCALSTORAGE = 'showedDarkmodeNotif';

/**
 * Function to render Darkmode notification
 * @returns {JSX.Element | null}
 */
function Darkmode({ lang }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCloseModal = () => {
    onClose();
    setItem(KEY_LOCALSTORAGE, true);
  };

  useEffect(() => {
    // show darkmode popup info IF user agent is Android & Darkmode
    if (!getItem(KEY_LOCALSTORAGE) && isDarkmode() && getDeviceOS() === 'android') {
      onOpen();
    }
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth="90%">
        {false && <ModalHeader>{txt.title[lang]}</ModalHeader>}
        <ModalBody>
          <Center>
            <Image
              src="https://user-images.githubusercontent.com/10141928/148669840-8e4a38a2-2949-406b-b828-f4a04c9dc3d9.png"
              width="80%"
            />
          </Center>
          <Text fontFamily="jost" dangerouslySetInnerHTML={{ __html: txt.description[lang] }} />
        </ModalBody>

        <ModalFooter>
          <Button
            size="sm"
            colorScheme="blackAlpha"
            bgColor="black"
            color="white"
            fontFamily="jost"
            onClick={handleCloseModal}
          >
            {txt.understand[lang]}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

Darkmode.propTypes = {
  lang: string.isRequired,
};

export default React.memo(Darkmode);
